// extracted by mini-css-extract-plugin
export var colcustomer = "about-module--colcustomer--2ENB_";
export var row = "about-module--row--L2Tp-";
export var col = "about-module--col--2VER_";
export var divcolor = "about-module--divcolor--3Gm5G";
export var customerheader = "about-module--customerheader--3fKsg";
export var h1primery = "about-module--h1primery--MSyM8";
export var pheader = "about-module--pheader--1e8Bh";
export var pheadera = "about-module--pheadera--3Mvcv";
export var h2primery = "about-module--h2primery--2A6gC";
export var imgabout = "about-module--imgabout--3HE5e";