import * as React from "react"
import Header from "../components/header"
import HeaderTop from "../components/headertop"
import FooterForm from "../components/form-footer"
import Footer from "../components/footer"
import Bgabout from "../components/bgabout"
import Brand from "../components/brand"
import { Container, Row, Col } from "react-bootstrap"
import * as aboutpageStyles from "../components/about.module.css"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

// markup
const IndexPage = () => {
  return (
    <main>
      <SEO title="เกี่ยวกับเรา" />
      <HeaderTop />
        <Header />
          <Bgabout  />
            <Container fluid="xl" className={aboutpageStyles.colcustomer}>
              <Row className={aboutpageStyles.row}>
                <Col md={3}></Col>
                <Col md={6} className={aboutpageStyles.col}>
                    <StaticImage src="../images/broucher-01.png" alt="BroucherHero" />
                    <p className={aboutpageStyles.pheader}>บริษัท ไทยโพลีแพ็ค จำกัด</p>
                    <h1>ผู้ผลิต <span className={aboutpageStyles.h1primery}>Flexible Packaging</span> หรือบรรจุภัณฑ์ชนิดอ่อน</h1>
                    <p className={aboutpageStyles.pheadera}>ซึ่งได้รับความนิยมในการนำไปใช้เป็นผลิตภัณฑ์สำหรับการบรรจุสินค้า อุปโภคและบริโภค อย่างแพร่หลายในชิชีวิตประจำวัน</p>
                </Col>
                <Col md={3}></Col>
              </Row>
            </Container>
            <div className={aboutpageStyles.divcolor}>
            <Container fluid="xl" className={aboutpageStyles.colcustomer}>
              <Row>
                <Col md={6}>
                  <h2 className={aboutpageStyles.h2primery}>บริษัท ไทยโพลีแพ็ค จำกัด</h2>
                  <p>มีความมุ่งมั่นในการผลิตบรรจุภัณฑ์รูปแบบต่างๆ หลากหลายเพื่อตอบสนอง ความต้องการของลูกค้าทั้งในและต่างประเทศ ด้วยกระบวนการผลิตที่ได้มาตรฐาน 
                    ทุกขั้นตอน รวมถึงการตรวจสอบคุณภาพสินค้าอย่างเข้มข้น เพื่อสร้างความเชื่อมั่นแก่ลูกค้าทุกราย
                  </p>
                  <StaticImage className={aboutpageStyles.imgabout} src="../images/product-01.png" alt="ProductHero" />
                </Col>
                <Col md={6}>
                  <StaticImage src="../images/about-page-service.png" alt="ProductHero" />
                </Col>
              </Row>
            </Container>
            </div>
            <Container fluid="xl" className={aboutpageStyles.colcustomer}>
              <Row className={aboutpageStyles.colcustomer}>
                <Col><h3 className={aboutpageStyles.customerheader}>ลูกค้าของเรา</h3></Col>
              </Row>
            </Container>
            <Brand />
        <FooterForm />
      <Footer />
    </main>
  )
}

export default IndexPage